import * as React from 'react'
import BackgroundIndex from '../images/index-background.png';

import IndexLineBlack from '../images/svg/index-line-black.svg';


const TermsPage = ({ data }) => {
  console.log(data)
  return (
      <main>
        <div className="bg-gray-900 lg:overflow-hidden relative" style={{  
            backgroundImage: "url(" + BackgroundIndex + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
            }}>
          <div className="mx-auto">
            <div className="">
              <div className="mx-auto md:items-center md:px-0">
                <div className="md:py-24 md:pl-20">
                  <h1 className="text-comorant lg:text-7xl text-6xl tracking-tight text-center text-white mb-6 pt-44 md:pt-0 md:px-0 px-14 uppercase">
                    <span className="block">TERMS AND CONDITIONS</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* CONTENT PRIVACY SECTION */}
      
        <div className="bg-light py-24 sm:py-32 lg:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="text-left">
            <p className='text-base mb-5'>The private members club is a separate unincorporated (&ldquo;Association&rdquo;).</p>

<p className='text-base mb-5'>Please read these terms and conditions carefully and, if you would like, print a copy for your records. These terms and conditions are set out in three parts: Part 1 - Website Terms and Conditions: This section is relevant to persons who make use of the services made available by Club Mella on the Website and who signup to become registered users (including verified registered users) of the Website. Part 2 - Association Terms and Conditions: this section is relevant to persons that become, or would like to become, members of the Association (&ldquo;Members&rdquo;) and to guests of Members (&ldquo;Guest&rdquo;) when they make use of the services offered, and events and parties hosted, by the Association. Part 3 - General Terms: this section covers general terms that apply to the matter referred to in both Part 1 and Part 2. Club Mella takes the protection of your personal data seriously. Please see the Club Mella Privacy Policy, which forms part of these Terms and Conditions, for detail of how Club Mella will use and process your personal data. Club Mella also uses cookies on the Website. Please see the Club Mella Cookie Policy for more details on how it and others use cookies and similar technologies.  If you have any questions regarding these Terms and Conditions or would otherwise like to get in touch, please contact customer service at info@clubmella.com. CLUB MELLA&rsquo;S WEBSITE TERMS AND CONDITIONS USD OF THE WEBSITE AND ONLINE SERVICE These Website Terms and Conditions (&ldquo;Web Terms&rdquo;) apply to your access and use of the Website and products and services made available by Club Mella (including its online community of women and men) through the Website (the &ldquo;Online Services&rdquo;). By accessing the Website and using the Services you agree to be legally bound by these Web Terms.</p>

<p className='text-base mb-5'>Club Mella may update these Web Terms from time to time for legal or regulatory reasons, or to reflect changes to the Online Services or the practices of Club Mella.</p>

<p className='text-base mb-5'>Club Mella will endeavor to notify you of any material change that affects you. However, you should regularly check this page to see if any changes have been made.</p>

<p className='text-base mb-5'>If you are a fee-paying registered user of the Website, any changes to these Web Terms will become effective from the date of your next payment following the change, unless Club Mella notifies you otherwise. If you are not a fee-paying registered user of the Website, any changes will become effective as soon as Club Mella notifies you of them or otherwise makes them available.</p>

<p className='text-base mb-5'>If you continue to use the Website or the Online Services following the effective date of any variation of these Web Terms, you will be deemed to have accepted such variation. If you do not agree to such variation, you should stop using the Website and the Online Services.</p>

<p className='text-base mb-5'> BECOMING A PARTY-VERIFIED MEMBER Parties and events are managed by the Association. Any member that is party verified can attend parties and events that are offered by the Association, but tickets for parties and events can only be booked by Members of the Association, except for certain events that are open to all members. Please read the Association Terms here to find out more about booking tickets for the Association&rsquo;s parties and events.</p>

<p className='text-base mb-5'>All Members have the option of becoming a &ldquo;Party Verified Member&rdquo; for a $50 USD application fee to cover the costs of assessing and vetting your application, ID checks, and other administration costs. This fee is non-refundable.</p>

<p className='text-base mb-5'>Benefits of being a Party Verified Member:</p>

<p className='text-base mb-5'>1. Increase your chances of interaction with other Members in the Club Mella online community by enabling Club Mella to verify that your online identity matches your real-world identity.</p>

<p className='text-base mb-5'>2. Attend parties and events hosted by the Association as a Member of the Association or Guest of a Member (subject to separate Association Member or Guest status (Please see the Association Terms for further details.).</p>

<p className='text-base mb-5'>Members can become Verified Members by completing the application form on the Verification page on the website.</p>

<p className='text-base mb-5'> CONDITIONS OF REGISTRATION, CODE OF BEHAVIOUR, CONFIDENTIALITY, AND CONTENT POLICY</p>

<p className='text-base mb-5'>When you apply to become a member, you must be aged 21 years old or over and may be asked to provide proof of your identity and a picture so that Club Mella&rsquo;s moderators can verify that you are a real person. If Club Mella has reason to believe that you are not a real person, Club Mella reserves the right to make your member registration conditional on you becoming a Party Verified Member.</p>

<p className='text-base mb-5'>Club Mella operates a strict Code of Behaviour (available here) for all Members. You must read the Code of Behaviour before registering as a Member and must comply with it at all time when using the Website and the Online Services, including when you interact with other members online and in person.</p>

<p className='text-base mb-5'>Any content or materials or information that you post on the Website and/or send to other Members must comply with our Content Policy (available here). See section 1.8 below for more information about the content on the Website.</p>

<p className='text-base mb-5'>Privacy, confidentiality, and discretion are core values that Club Mella requires all Members to abide by. Do not discuss or disclose any non-public information about Club Mella or other Members to anyone else.</p>

<p className='text-base mb-5'>You must not allow any other person to access your Club Mella account and/or use your login or password.</p>

<p className='text-base mb-5'>Club Mella reserves the right to accept or reject any application to become Party Verified in its absolute discretion.</p>

<p className='text-base mb-5'>No person shall be denied becoming Party Verified on the grounds of race, ethnic origin, creed, color, age, disability, occupation, sexual orientation, religion, political or other beliefs.</p>

<p className='text-base mb-5'> PAYMENT AND AUTO-RENEWAL</p>

<p className='text-base mb-5'>Member registration fees are payable in advance for each type of Member registration and Registration Period.</p>

<p className='text-base mb-5'>Your Member registration will automatically renew and your chosen payment method will be automatically recharged at the end of each Registration Period unless you choose to terminate your Member registration in accordance with Section 1.5 (Cancellation, Termination, and Refunds).</p>

<p className='text-base mb-5'> CANCELLATION AND REFUNDS</p>

<p className='text-base mb-5'>When you apply to register as any type of Member you immediately agree to receive the Online Services available under that registration type.</p>

<p className='text-base mb-5'>As a consumer, you have the right to cancel your digital subscription within fourteen(14) days from your purchase date. (the &ldquo;Cancellation Period&rdquo;).</p>

<p className='text-base mb-5'>To exercise your right to cancel your Premium membership, you must within the fourteen (14) days Cancellation Period, contact Club Mella at membership@clubmella.com with a notification clearly stating your decision to cancel your Member registration. Please include details of your name, email address, and user name so that Club Mella can identify your account.</p>

<p className='text-base mb-5'>If you cancel your Premium membership during the Cancellation Period and:</p>

<p className='text-base mb-5'>if you have already accessed or used the Online Services, for example by sending messages to other Members, Club Mella will refund you the Fee less a pro-rata amount that corresponds to the number of days that you have actually accessed or used the Online Services, so that you are only charged for your actual use of the Online Services. For example, if you have only accessed or used the Online Services on one day during the Cancellation Period, you will only be charged for one day&rsquo;s worth of access to or use of the Online Services.</p>

<p className='text-base mb-5'>Club Mella will provide you with the applicable refund amount within fourteen (14) days of the date on which Club Mella received your notice to cancel your membership. Club Mella will use the same means of payment for your refund that you used for subscribing to the Online Services.</p>

<p className='text-base mb-5'>Other than your cancellation rights above, you may terminate your Premium membership at any time by sending an email to membership@clubmella.com. Termination by you will take effect at the end of the current Registration Period.</p>

<p className='text-base mb-5'>Club Mella may terminate your membership at any time if in its reasonable opinion you have breached any of these Web Terms, including the Code of Behaviour or Content Policy set out in these Web Terms, or any other terms that relate to your member registration. In such circumstances, the termination will take effect immediately and you will not be entitled to any refunds.</p>

<p className='text-base mb-5'>Club Mella may also terminate your membership at any time and for any reason to take effect immediately. In these circumstances, you will receive a pro-rata refund of Fees that relate to any remaining Registration Period.</p>

<p className='text-base mb-5'>At the relevant termination date, Club Mella will deactivate your account and your access to the Online Services will cease. Club Mella will delete all content stored in your account and your user details (except where it is required by law to retain such details or need to retain any details for legal reasons).</p>

<p className='text-base mb-5'> MERCHANDISE AND OTHER PURCHASES</p>

<p className='text-base mb-5'>Club Mella may offer you the opportunity to purchase various items of merchandise through the Website. All purchases are made through third-party retailers and you will be directed to their sites to make the relevant purchase.</p>

<p className='text-base mb-5'>Club Mella is not involved in any elements of the sale or payment processing and is not responsible for any third-party sales.</p>

<p className='text-base mb-5'> INTELLECTUAL PROPERTY</p>

<p className='text-base mb-5'>The Website and any information, text, message articles, music, videos, images, photographs, and other content displayed on the Website or otherwise used by Club Mella in providing the Online Services (&ldquo;Content&rdquo;) are all protected by certain intellectual property and other rights. These rights include all copyright, database rights, performer&rsquo;s property rights, moral rights, trademarks and service marks, business names and domain names, goodwill and the right to sue passing off or unfair competition, rights in designs, patents, rights to inventions and all other intellectual property and proprietary rights, in each case whether registered or unregistered. These rights either belong directly to Club Mella or are licensed to Club Mella from their respective owners or licensors.</p>

<p className='text-base mb-5'>You may only use, access, and view the Website and the Content of your own personal, non-commercial use subject to a limited temporary license from Club Mella, You may not use Club Mella or any Content for any purpose other than those set out above.</p>

<p className='text-base mb-5'>You may not at any time modify, store, copy (including for example scraping, using crawlers, browser plugins and add-ons, and any other technology or manual work), extract, reutilize, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or distribute any part, content or data of the Website or the Online Service.</p>

<p className='text-base mb-5'> YOUR CONTENT / USER CONTENT</p>

<p className='text-base mb-5'>Club Mella may enable users to upload content to the Website or to take part in one-to-one conversations and discussions via our services (&ldquo;Your Content&rdquo; or &ldquo;User Content&rdquo;).</p>

<p className='text-base mb-5'>Your Content must comply with the Content Policy and the Code of Behaviour.</p>

<p className='text-base mb-5'>You take full responsibility for any of Your Content that you or Club Mella publish, and agree to be responsible for any claim made against Club Mella by any third party in relation to Your Content.</p>

<p className='text-base mb-5'>You own the intellectual property and other rights in Your Content. However, you give Club Mella a limited license to publish Your Content in relation to the Website.</p>

<p className='text-base mb-5'>Club Mella cannot guarantee the accuracy or quality of any User Content posted on or sent via Club Mella. While Club Mella endeavors to enforce the rules set out in these Web Terms, Club Mella does not promise to monitor or moderate User Content published on or sent via the Website. Further, we are not responsible for the views in or otherwise in any way for any User Content.</p>

<p className='text-base mb-5'>If you believe that any User Content published on or made available via Club Mella fails to comply with these Web Terms, you can notify Club Mella by emailing us at info@clubmella.com. Upon receiving any complaints about User Content, we will review the User Content in question and may then decide to remove or edit it.</p>

<p className='text-base mb-5'> AVAILABILITY OF THE WEBSITE AND ONLINE SERVICES</p>

<p className='text-base mb-5'>The Website and Online Services are available on a temporary basis. Club Mella may update the Website and may change the Content and Online Services at any time. Club Mella reserves the right to withdraw or amend the Website and/or Online Services at any time and without notice. Club Mella will not be liable to you for any resulting loss or damage (to the extent Club Mella is allowed to restrict liability by law) if the Website or the Online Services are unavailable at any time for any period &ndash; unless you are a Fee-paying member in which case Club Mella may reimburse you accordingly.</p>

<p className='text-base mb-5'>You are responsible for making all arrangements necessary to access the Website and the Online Services. Use of the Website and Online Services may be subject to your computer and/or device complying with minimum standard technical specifications and compatibility requirements. Club Mella shall not be liable for any failure due to any incompatibility (including, without limitation, minimum storage and memory requirements from time to time).</p>

<p className='text-base mb-5'> LINKING TO CLUB MELLA</p>

<p className='text-base mb-5'>You may link to Club Mella, provided you do so in a way that is fair and legal and which does not damage Club Mella&rsquo;s reputation or take advantage of it. In particular, you must not establish a link in such as way as to suggest any form of association, approval, or endorsement on Club Mella&rsquo;s part where none exists. The Website must not be framed on any other site, nor should you establish a link to any part of the Website other than the homepage.</p>

<p className='text-base mb-5'>If you wish to make any use of the Content other than the set out above, please address your request to info@clubmella.com</p>

<p className='text-base mb-5'>Club Mella reserves the right to withdraw linking permission without notice.</p>

<p className='text-base mb-5'> LINKS FROM CLUB MELLA</p>

<p className='text-base mb-5'>The Website and/or the Content may contain links to third-party websites and services. If you decide to visit any third-party site or use a third-party service, you do so at your own risk. Club Mella is not responsible for the content, accuracy, or opinions expressed on such websites or services. Links do not imply that Club Mella is affiliated with or associated with such sites or services.</p>

<p className='text-base mb-5'>Your browsing and interaction on any other website or your use of other services, including websites and services which have a link to the Website, is subject to that operator&rsquo;s own rules and policies. Please read those rules and policies before proceeding.</p>

<p className='text-base mb-5'> ASSOCIATION TERMS AND CONDITIONS INTRODUCTION</p>

<p className='text-base mb-5'>These Association Terms and Conditions (&ldquo;Association Terms&rdquo;) set out the rules of the Association and the terms and conditions that apply to (1) Association membership and (2) the Association&rsquo;s provision to Members or Guests, with products and services, including parties (&ldquo;Parties&rdquo; or each a &ldquo;Party&rdquo;), workshops and events (&ldquo;Events&rdquo; or each an &ldquo;Event&rdquo;) that are hosted by or for the Association.</p>

<p className='text-base mb-5'>By purchasing a ticket to a Party/Event and/or attending a Party/Event, you agree to be legally bound by these Association's Terms.</p>

<p className='text-base mb-5'>These Association Terms should also be read in conjunction with Club Mella&rsquo;s Web Terms.</p>

<p className='text-base mb-5'> OBJECTS OF THE ASSOCIATION</p>

<p className='text-base mb-5'>The Association is a private members&rsquo; club that is focused on the pursuit of couples and females pleasure and empowering couples and females to explore their sexuality in a safe environment where couples and females are in control.</p>

<p className='text-base mb-5'> MEMBERSHIP APPLICATION AND CRITERIA</p>

<p className='text-base mb-5'>To meet the objectives of the Association, you can apply to become a Member if you satisfy all the following eligibility criteria:</p>

<p className='text-base mb-5'>- you are a female or couple; - you are aged 21 years old or over; - you are registered as a member on the Club Mella Websites; and - you have received Party Verified status,</p>

<p className='text-base mb-5'>(&ldquo;Eligibility Criteria&rdquo;).</p>

<p className='text-base mb-5'>Please do not apply to become a Member unless and until you satisfy the Eligibility Criteria.</p>

<p className='text-base mb-5'>To apply to become a Member, please complete and submit the verification process on the website.</p>

<p className='text-base mb-5'> ACCEPTANCE OF APPLICATION</p>

<p className='text-base mb-5'>The Association is an exclusive private members&rsquo; club with limited capacity. As such, the Management Committee reserves the right to accept or reject any application for Association membership at its absolute discretion.</p>

<p className='text-base mb-5'>No person shall be denied membership of the Association on the grounds of race, ethnic origin, creed, color, age, disability, occupation, sexual orientation, religion, political or other beliefs.</p>

<p className='text-base mb-5'> VIP ASSOCIATION MEMBERSHIP</p>

<p className='text-base mb-5'>You may be invited by the Management Committee, at the Management Committee&rsquo;s absolute discretion, to become a &ldquo;VIP Member&rdquo;. VIP Members receive all of the entitlements of Members, plus:</p>

<p className='text-base mb-5'>- early access to Party/Event tickets, and - exclusive offers and discounts.</p>

<p className='text-base mb-5'>Additional charges may apply to become a VIP Member.</p>

<p className='text-base mb-5'> TICKETS AND ACCESS TO PARTIES AND EVENTS</p>

<p className='text-base mb-5'>Members</p>

<p className='text-base mb-5'>As a Member, you are entitled to apply to purchase one Member ticket for each Party/Event. Notwithstanding the foregoing, certain Parties/Events may be open to a restricted class of Members.</p>

<p className='text-base mb-5'>No person other than Members shall be entitled to apply to purchase tickets (whether those tickets are Member Tickets or Guest Tickets) for Parties/Events. Notwithstanding this, the Association may, at its absolute discretion, make tickets for some Events (but not Parties) available for purchase by any member (and not just Members) (&ldquo;Unrestricted Events&rdquo;). Where the context requires, references in these Association Terms to &lsquo;Events&rsquo; shall be construed as including references to Unrestricted Events.</p>

<p className='text-base mb-5'>Guests</p>

<p className='text-base mb-5'>As a Guest, you cannot purchase tickets for Parties/Events (except Unrestricted Events &ndash; see above) but you can attend Parties/Events in accordance with this section:</p>

<p className='text-base mb-5'>Members are entitled to apply to purchase Guest ticket(s) (for non-Members) for each Party/Event. The maximum number of applications for Guest tickets that can be made by a Member for any given Party shall be set on a case-by-case basis by the Management Committee.</p>

<p className='text-base mb-5'>GUEST TICKETS CAN ONLY BE USED BY MALES OR FEMALES WHO SATISFY THE FOLLOWING CONDITIONS:</p>

<p className='text-base mb-5'>- the person is registered as a Member on the Club Mella Website; - the person has received Party Verified Member status; and  - the person has not breached the Web Terms or these Association Terms, including, in particular, the Code of Behaviour.</p>

<p className='text-base mb-5'>Parties/Events are limited by capacity and other restrictions may apply on a case-by-case basis.</p>

<p className='text-base mb-5'>Club Mella (at the direction of the Management Committee) manages applications for tickets on behalf of the Association and Members (or Guests, in respect of Unrestricted Events) must apply for tickets to Parties/Events via the Website.</p>

<p className='text-base mb-5'>Acceptance by the Association of an application and the Association&rsquo;s allocation of tickets is at the Association&rsquo;s absolute discretion. Even if you have purchased a ticket, entry to and continued attendance at a Party/Event is at the Management Committee&rsquo;s absolute discretion. As such, the Management Committee may refuse your entry or ask you to leave a Party/Event at its absolute discretion.</p>

<p className='text-base mb-5'>TICKETS ARE NON-REFUNDABLE AND NON-TRANSFERABLE. IF YOU CANNOT ATTEND, YOU WILL NOT RECEIVE A REFUND FOR ANY COSTS OF THE TICKET OR OTHERWISE.</p>

<p className='text-base mb-5'>IN THE EVENT THAT A PARTY/EVENT IS CANCELLED BY THE ASSOCIATION, YOU WILL BE OFFERED CREDIT FOR THE VALUE OF YOUR TICKET PRICE, BUT NEITHER Club Mella NOR ANY MEMBER OF THE ASSOCIATION SHALL BE LIABLE FOR ANY OTHER COSTS THAT YOU MAY HAVE INCURRED, SUCH AS ACCOMMODATION OR TRAVEL EXPENSES.</p>

<p className='text-base mb-5'>In any event Members and Guests must at all times comply with the Party and Event Policy (&ldquo;Party and Event Policy&rdquo;), which incorporates the Club Mella Code of Behaviour.</p>

<p className='text-base mb-5'>IT IS THE RESPONSIBILITY OF MEMBERS TO ENSURE THAT THEY AND THEIR GUESTS ADHERE TO THESE ASSOCIATION TERMS, INCLUDING THE PARTY AND EVENT POLICY.</p>

<p className='text-base mb-5'>Tickets &amp; Event Details are shared with members of the Member Association. The responsibility lies with the member to share the details with their verified partner.</p>

<p className='text-base mb-5'> TERMINATION OF MEMBERSHIP AND EXPULSION</p>

<p className='text-base mb-5'>A Member shall be entitled to terminate their membership of the Association at any time by emailing info@clubmella.com.</p>

<p className='text-base mb-5'>A Member&rsquo;s membership of the Association shall also be automatically terminated if the Member ceases to satisfy the Association&rsquo;s eligibility criteria for membership (including, for the avoidance of doubt, where the Member cancels or, or Club Mella terminates, their membership registration in respect of the Website.</p>

<p className='text-base mb-5'>The Management Committee reserves the right to expel any Member from the Association, without prejudice to that person&rsquo;s continuing membership registration in respect of the Website, if the Management Committee determines, in its absolute discretion, that the Member has violated these Association Terms, including the Party and Event Policy (incorporating the Code of Behaviour).</p>

<p className='text-base mb-5'> GENERAL TERMS</p>

<p className='text-base mb-5'> DISCLAIMERS</p>

<p className='text-base mb-5'>Whilst Club Mella does its best to vet applicants for membership registration and applications for Verified membership status, to check that they are real persons and/or to verify their real identity, Club Mella does not perform any other background checks (such as criminal record checks or sexual health), nor does it interview or actively vet or select members.</p>

<p className='text-base mb-5'>Club Mella will (for itself and on behalf of the Association) endeavor to provide a safe and respectful online and real-world environment for members, Members, and Guests (as applicable). However, Club Mella cannot control the behavior of other members, Members, or Guests and you are urged at all times to take care and be sensible in your interactions with other members, Members, or Guests, in particular as regards your safety and sexual activity. You are solely responsible for your interactions with other members, Members, or Guests and for your safety. Neither Club Mella nor any Member of the Association (including the Management Committee) is responsible for the conduct of any members, Members' Guests or their User Content.</p>

<p className='text-base mb-5'>Club Mella warrants that it will provide the Online Services and the Website with reasonable skill and care and substantially as described in the Web Terms.</p>

<p className='text-base mb-5'>Club Mella warrants on behalf of the Association that it will provide Parties with reasonable skill and care and substantially as described in the Association Terms.</p>

<p className='text-base mb-5'>Other than the express warranty in this section, the Website, the Online Services, and the Parties are provided on an &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; basis without any representation or endorsement made and without further warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security, and accuracy.</p>

<p className='text-base mb-5'>No party to these Terms and Conditions makes any warranty that the Website, the Online Service, or the Parties will meet your requirements, that Content will be accurate or reliable, nor does Club Mella warrant that the functionality of the Website will be uninterrupted or error-free, that defects will be corrected or that the Website is free of viruses or anything else which may be harmful or destructive. Nothing in these Terms and Conditions shall have the effect of excluding a party&rsquo;s liability for fraud or for death or personal injury caused by that party&rsquo;s negligence (as applicable).</p>

<p className='text-base mb-5'>  YOUR LIABILITY TO Club Mella AND THE ASSOCIATION</p>

<p className='text-base mb-5'>You will be responsible for and indemnify any other party to these Terms and Conditions against all claims, liabilities, damages, costs, and expenses suffered or incurred by that other Party as a result of your breach of the Web Terms, the Association Terms, or other default in relation to any codes, policies or your other obligations.</p>

<p className='text-base mb-5'> CLUB MELLA&rsquo;S LIABILITY TO YOU</p>

<p className='text-base mb-5'>Neither Club Mella nor any Member of the Management Committee shall be liable for any loss or damage caused by Club Mella or any Member of the Association or any of their employees or agents in circumstances where there has been no breach of a legal duty of care which is owed by Club Mella or the Management Committee or any of their employees or agents, and/or the loss or damage is not a reasonably foreseeable result of any breach of the Web Terms and/or the Association Terms.</p>

<p className='text-base mb-5'>In the absence of any negligence or other breach of duty by Club Mella or a member of the Management Committee, the use by you of the Website, the Online Services, and your attendance at Parties (as applicable) is entirely at your risk.</p>

<p className='text-base mb-5'>Nothing in the Terms and Conditions shall affect your statutory rights as a consumer.</p>

<p className='text-base mb-5'>Club Mella provides members and their guests a licensed venue for socializing and erotic performance and is not liable for any sexual activity that a guest participates in or any damage arising directly or indirectly from it.</p>

<p className='text-base mb-5'> PERSONAL CONTRACT &ndash; NO ASSIGNMENT, AND SURVIVAL OF TERMS</p>

<p className='text-base mb-5'>membership registration and Membership of the Association are not transferable or assignable and shall not be used by any person other than the registered membership or Member.</p>

<p className='text-base mb-5'>Sections within these Terms and Conditions that expressly or by their inherent nature should survive termination or expiry of these Terms and Conditions shall survive termination or expiry and shall continue to have an effect and be binding.</p>

<p className='text-base mb-5'> GOVERNING LAW</p>

<p className='text-base mb-5'>The state of Delaware shall have sole and exclusive jurisdiction and venue for the resolution of all disputes arising under the terms of this Agreement and the transactions contemplated herein.</p>

<p className='text-base mb-5'> CODE OF BEHAVIOUR As a registered Member and/or Member or Guest of the Association, you acknowledge and agree to comply with the following rules of behavior:</p>

<p className='text-base mb-5'> Act honestly and respectfully to others at all times; Be discrete at all times and respect other people&rsquo;s confidentiality and privacy; Do not harass, abuse, or harm another person; Comply at all times with the Club Mella Web Terms, the Association Terms(if applicable), and all applicable laws, regulations, rules, and directions; Do not use any image that is not your likeness for your member profile; Do not misrepresent your relationship with a person or entity, past or present; Provide accurate information to Club Mella, the Association, and other Members and keep it updated; Do not use images or terms in your descriptions or member profile that are offensive, obscene, pornographic, indecent, malicious, threatening, abusive, defamatory, or otherwise unlawful; Do not creaete member profile for anyone other than yourself; Do not use or attempt to use another member&rsquo;s account or impersonate another member; Do not collect or store another member&rsquo;s personal data without their knowledge; Permanently delete and erase another member&rsquo;s personal data on request of that other member; Do not interfere or violate a third party&rsquo;s private life, interfere with their private communications, or breach any duties of confidence or confidentiality; Understand and comply at all times with the Club Mella Content Policy; Any commercial use of the Online Service, such as promotion via messaging, or the Club Mella name is strictly prohibited.</p>

<p className='text-base mb-5'> CONTENT POLICY As a registered member you acknowledge and agree to comply with the following content rules:</p>

<p className='text-base mb-5'> any information or content you post or share must comply with all applicable laws, including, without limitation, privacy laws, obscene publications, intellectual property laws, anti-spam laws, and regulatory requirements; do not upload pornographic or indecent images to the Website. Such images will not be verified or published on the site; do not act in an unlawful, libelous, abusive, obscene, discriminatory, racist, sexist, fraudulent, or otherwise objectionable manner; do not use any content in any manner that may infringe any of Club Mella&rsquo;s rights or the rights of a third party, including intellectual property rights such as copyright, trademarks or databases; do not post, share or otherwise publish or link to content that contains videos, stills, photographs, audio, or images of another person without their permission; do not share or disclose information that you do not have the right to share or disclose (such as confidential information of others); do not post, link to, or otherwise publish any content about illegal activities such as making or buying drugs or illegal weapons; do not send spam or other unwelcome communications to others; do not use the Website for any commercial purpose under any circumstances</p>

<p className='text-base mb-5'> PARTY AND EVENT POLICY ADMISSION The admission of Members and Guests to any Party is strictly subject to this Party and Event Policy. By purchasing a ticket to attend a Party and/or attending a Party, Members and Guests agree to be bound by this Party and Event Policy and the Code of Behaviour. Members shall ensure that Guests are made aware of and adhere to this Party and event Policy and the Code of Behaviour. The Management Committee reserves the right to refuse access to or order the immediate removal without any entitlement to a refund of, any person who fails to act in accordance with this Party and Event Policy or the Code of Behaviour. Tickets are not refundable. The Association shall not be under any obligation to rectify tickets bought in error. Member tickets are personal to the Member purchasing such tickets or to the Guest for whom the ticket was purchased. Members and Guests may not resell or trade any tickets. Members and Guests will be asked to verify their identity (which may include their membership registration) upon entry to a Party. Club Mella Parties were created to give Members a safe space to explore without judgment and to help them become empowered in all aspects of their lives. Guests are more than welcome to join them in this world, showing respect and adhering to the rules. Any Guest found to be in breach of the rules will be removed immediately and will have their membership revoked. This will also apply to the Members of that party. Members are responsible for their Guest and their actions. MOBILE PHONES Members and Guests shall not take or make phone calls and shall ensure that phones are in silent mode, whilst at a Party. Phones may be only used in designated areas. In some Parties/Events, Carrying Phones is strictly prohibited and all mobile devices must be stored in designated areas.</p>

<p className='text-base mb-5'>CAMERAS/RECORDING DEVICES No cameras, video or recording devices, live-streaming or otherwise &ndash; whether on a mobile phone or other devices, may be used at a Party. The Management Committee reserves the right to take possession of and confiscate any mobile phone, camera, video or recording device, and any photos, videos, or other recorded images used or taken at a Party. PRESS / SOCIAL MEDIA Club Mella and the Association operate a strict no-press and no-social media policy. You must not discuss or disclose to any press, nor post on social media, any information about Parties or other members. Parties are confidential and private occasions. Members and Guests are reminded of their obligations under the Association Terms.</p>

<p className='text-base mb-5'>DRUGS AND SMOKING Members and Guests shall not bring alcohol or illegal substances onto Party premises. Licensing laws shall be strictly enforced. Anyone who is or appears to be intoxicated shall be removed from the Party immediately without any entitlement to a refund. Parties will in general be non-smoking and non-vaping events. Please follow directions at Parties to find designated smoking areas.</p>

<p className='text-base mb-5'>LIABILITY Club Mella will, on behalf of the Association, endeavor to provide a safe and respectful environment for Members and Guests. However, Club Mella cannot control the behavior of other Members or Guests and you are urged at all times to take care and be sensible in your interactions with other Members and Guests. You are solely responsible for your interactions with other Members and Guests and for your own safety. Neither Club Mella nor the Management Committee is responsible for the conduct of any Member or Guest or their User Content. Club Mella accepts no responsibility for the loss, theft, damage, or alteration to any personal items brought onto Party premises. Members and Guests shall not exhibit behavior that is considered antisocial or disruptive or contradicts the Code of Behaviour. Such behavior will result in immediate removal from the Party without any entitlement to a refund. Club Mella reserves the right to report any Member or Guest deemed to be exhibiting illegal, antisocial, or disruptive behavior to the police for further investigation.</p>
            </div>
          </div>
        </div>

      </main>
  )
}



export default TermsPage